import { useContext, useEffect } from 'react';
import { Col, Row, Timeline } from 'antd';
import { useParams } from 'react-router-dom';
import ButtonIcon from 'components/buttonIcon';
import { Title } from 'pages/Analytics/components/ActiveUsersCollapse/styles';
import { SectionCard } from 'pages/Analytics/components/AnalyticsContent/styles';
import { SessionContext } from 'contexts/session';
import { ActivityLogContext } from 'contexts/activityLog';
import { ActivityLogContainerMobile } from './styles';
import ActivityLogItemMobile from './ActivityLogMobile/ActivityLogItemMobile';
import ActivityLogDrawer from './ActivityLogMobile/ActivityLogDrawer';

const ActivityLogContentMobile = (): JSX.Element => {
  const { id } = useParams<{ id: string }>();
  const { showActivityLogDrawer, setShowActivityLogDrawer } = useContext(SessionContext);
  const { isStaff } = useContext(SessionContext);
  const { actions, fetching, isEmpty, table } = useContext(ActivityLogContext);

  useEffect(() => {
    if (isStaff) {
      actions.onChangeFilters([
        { name: 'account_id', value: id },
        { name: 'from_date', value: null },
        { name: 'to_date', value: null },
      ]);
      actions.onRetrieveActivityLogUsers(id);
    } else {
      actions.onChangeFilters([
        { name: 'from_date', value: null },
        { name: 'to_date', value: null },
      ]);
      actions.onRetrieveActivityLogUsers();
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      actions.onRefreshActivities();
    }, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (!showActivityLogDrawer) {
      if (isStaff) {
        actions.onResetActivities(id);
        actions.onRetrieveActivityLogUsers(id);
      } else {
        actions.onResetActivities();
        actions.onRetrieveActivityLogUsers();
      }
    }
  }, [showActivityLogDrawer]);

  return (
    <SectionCard padding={'20px'} rounded dashboard shadow>
      <header>
        <Row>
          <Col xs={20} style={{ paddingTop: '5px', lineHeight: '26px' }}>
            <Title style={{ fontSize: '20px' }}>Account recent activity</Title>
          </Col>
          <Col xs={4}>
            <ButtonIcon
              $terciary
              icon={'ArrowRight'}
              onClick={() => setShowActivityLogDrawer(true)}
              style={{ float: 'right' }}
            />
          </Col>
        </Row>
      </header>
      <ActivityLogContainerMobile fetching={fetching}>
        {!isEmpty && (
          <Timeline>
            {table?.items?.map((item, index) => {
              return <ActivityLogItemMobile key={index} activity={item} />;
            })}
          </Timeline>
        )}
      </ActivityLogContainerMobile>
      {showActivityLogDrawer && (
        <ActivityLogDrawer activityLogDrawerVisible={showActivityLogDrawer} />
      )}
    </SectionCard>
  );
};

export default ActivityLogContentMobile;
